import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const AddSubCategory = ({ show, handleClose, refresh, category }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const payload = {
      ...category,
      subCategory: data?.subCategory,
    };
    delete payload.categoryName;
    console.log(payload);

    axios
      .post(`https://backend.canursvp.com/api/addCategory`, payload)
      .then((res) => {
        console.log(res);
        handleClose();
        refresh();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add SubCategory for {category?.categoryName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav flex-column gap-3">
            <li>
              <label className="mb-1 text-capitalize fw-bold" htmlFor="email">
                SUB CATEGORY:
              </label>
              <input
                className="form-control"
                type="subCategory"
                name="subCategory"
                placeholder="Enter your Sub Category..."
                {...register("subCategory", { required: true })}
              />
              {errors.subCategory && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default AddSubCategory;
