import React from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

const Layout = ({ children }) => {
  const links = [
    { path: '/', name: 'Users', icon: 'bi-person', exact: true },
    {
      path: '/categories',
      name: 'categories',
      icon: 'bi-layout-text-window',
    },
    { path: '/cards', name: 'Cards', icon: 'bi-card-image' },
  ];

  return (
    <div className='d-flex min-vh-100'>
      {/* Sidebar */}
      <div
        className='d-none d-md-flex flex-column flex-shrink-0 p-3 bg-light shadow-sm'
        style={{ width: 280 }}
      >
        <Link
          to='/'
          className='d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none'
        >
          <i className='bi-1-circle fs-2 me-2'></i>
          <span className='fs-4'>devOne Admin</span>
        </Link>
        <hr />
        <ul className='nav nav-pills flex-column mb-auto sticky-top'>
          <li className='nav-item'>
            {links.map((item) => (
              <NavLink
                end={item.exact}
                key={item.name}
                to={item.path}
                className='nav-link text-capitalize'
              >
                <i className={`bi fs-5 me-2 ${item.icon} `}></i>
                {item.name}
              </NavLink>
            ))}
          </li>
        </ul>
        <hr />
        <Dropdown>
          <Dropdown.Toggle variant='...' className='d-flex align-items-center'>
            <div
              style={{ width: 32, height: 32 }}
              className='bg-primary bg-opacity-25 text-black fw-bold small rounded-circle me-2 d-flex align-items-center justify-content-center'
            >
              A
            </div>
            <span>Admin</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={Link} to='/'>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* /Sidebar */}
      <div className='flex-fill mx-0 mx-md-4 my-4 pb-5 pb-md-0'>
        <div>{children}</div>
      </div>
      {/* Mobile Menu */}
      <Navbar className='d-md-none fixed-bottom px-3' bg='dark' variant='dark'>
        <ul className='nav nav-pills w-100'>
          <li className='d-flex gap-2 justify-content-between w-100'>
            {links.map((item) => (
              <NavLink
                end
                key={item.name}
                to={item.path}
                className='nav-link text-capitalize d-flex flex-column justify-content-center align-items-center'
              >
                <i className={`bi fs-4 ${item.icon} `}></i>
              </NavLink>
            ))}
            <Dropdown drop='up' align='end'>
              <Dropdown.Toggle
                variant='...'
                className='d-flex align-items-center'
              >
                <div
                  style={{ width: 32, height: 32 }}
                  className='bg-primary text-white fw-bold small rounded-circle me-2 d-flex align-items-center justify-content-center'
                >
                  A
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to='/'>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </Navbar>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Layout;
