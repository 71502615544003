import { BrowserRouter, Routes, Route } from 'react-router-dom';
import paths from './paths';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {paths.map((item) => (
          <Route
            key={item.path}
            exact
            path={`${item.path}`}
            element={item.component}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;