import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const AddCategory = ({ show, handleClose, refresh }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const res = await axios.post(
        `https://backend.canursvp.com/api/addCategory`,
        data
      );
      handleClose();
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Added Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav flex-column gap-3">
            <li>
              <label className="mb-1 text-capitalize fw-bold" htmlFor="email">
                Category:
              </label>
              <input
                className="form-control"
                type="categoryName"
                name="categoryName"
                placeholder="Enter Category"
                {...register("categoryName", { required: true })}
              />
              {errors.categoryName && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </li>
            <li>
              <label className="mb-1 text-capitalize fw-bold" htmlFor="email">
                Sub Category:
              </label>
              <input
                className="form-control"
                type="subCategory"
                name="subCategory"
                placeholder="Enter your Sub Category..."
                {...register("subCategory", { required: true })}
              />
              {errors.subCategory && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default AddCategory;
