import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { toast, Slide } from "react-toastify";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";

const CardsPage = () => {
  const cards = [{}, {}, {}, {}, {}, {}, {}, {}];
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);

  const [isData, setData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(`https://backend.canursvp.com/api/card/get`);

      setData(data?.cardList);
    } catch (error) {}
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `https://backend.canursvp.com/api/card/delete/${id}`
      );
      toast.success("Deleted successfully", {
        hideProgressBar: false,
        autoClose: 2000,
        transition: Slide,
        progress: undefined,
      });
      getData();
    } catch (error) {}
  };

  const fetchCategoryType = async () => {
    try {
      const { data } = await axios.get(`https://backend.canursvp.com/api/category`);

      let categories = [];
      let subCategories = [];

      data?.data.forEach((parent) => {
        // Push parent category to categories array
        categories.push({
          _id: parent._id,
          name: parent.categoryName,
        });

        // Push each subcategory along with its parent categoryId to subCategories array
        parent?.subcategories.forEach((child) => {
          subCategories.push({
            categoryId: parent._id, // Use the parent category _id
            name: child?.name,
            _id: child?._id,
          });
        });
      });

      // Update the state with the fetched categories and subcategories
      setCategoryData(categories);
      setSubCategoryData(subCategories);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error fetching category data:", error.message);
    }
  };

  useEffect(() => {
    getData();
    fetchCategoryType();
  }, []);
  return (
    <Layout>
      <div className="container">
        {/* Header */}
        <div className="d-flex flex-wrap gap-3 mb-4">
          <h3 className="mb-0 text-capitalize me-auto">List Of Cards</h3>
          <Button as={Link} to="/cards/card">
            Add Card
          </Button>
        </div>
        {/* /Header */}
        <ul className="nav row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4 gy-4">
          {isData?.map((item) => (
            <li>
              <Card>
                <Card.Img
                  style={{ height: "12.5rem", objectFit: "cover" }}
                  variant="top"
                  src={item?.imgUrl}
                />
                <Card.Body>
                  <Card.Title>{item?.title}</Card.Title>
                  <p>
                    category:
                    {
                      categoryData.find((val) => val._id === item?.categoryId)
                        ?.name
                    }
                  </p>

                  <p>
                    sub category:
                    {
                      subCategoryData.find(
                        (val) => val._id === item?.subCategoryId
                      )?.name
                    }
                  </p>
                </Card.Body>
                <Card.Body className="pt-0 d-flex gap-2">
                  <Card.Link
                    as={Link}
                    to={`/cards/${item?._id}`}
                    className="btn btn-sm btn-primary"
                  >
                    Edit
                  </Card.Link>
                  <button
                    onClick={() => handleDelete(item?._id)}
                    type="button"
                    className="btn btn-sm btn-danger"
                  >
                    Delete
                  </button>
                </Card.Body>
              </Card>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default CardsPage;
