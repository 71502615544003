import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { Box } from "./Box.js";

export const DraggableComponent = ({
  hideSourceOnDrag,
  imageUrl,
  bgColor,
  setBoxes,
  boxes,
}) => {
  // const [boxes, setBoxes] = useState({
  //   a: {
  //     top: 150,
  //     left: 40,
  //     title: `Just a note to thank you
  //   and a line to tell you,
  //   too nothing was more welcomed
  //   than the lovely gift from yo`,
  //   },
  //   b: { top: 325, left: 110, title: "Drag me too" },
  // });
  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes, setBoxes]
  );

  const editTitle = useCallback(
    (id, title) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { title },
          },
        })
      );
    },
    [boxes, setBoxes]
  );
  const editColor = (id, color) => {
    console.log(id, color);
    setBoxes(
      update(boxes, {
        [id]: {
          $merge: { color },
        },
      })
    );
  };
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <div
      className="card-editing mx-auto"
      style={{ backgroundColor: `${bgColor}` }}
    >
      <div
        className="h-100 w-100 bg-cover "
        ref={drop}
        style={{
          backgroundImage: `url(${imageUrl})`,
          height: "200px",
          width: "200px",
        }}
      >
        {Object.keys(boxes).map((key) => {
          const { left, top, title, color } = boxes[key];

          return (
            <Box
              key={key}
              id={key}
              left={left}
              top={top}
              hideSourceOnDrag={hideSourceOnDrag}
              CustomMessage={title}
              setBoxes={setBoxes}
              boxes={boxes}
              editTitle={editTitle}
              editColor={editColor}
              color={color}
            />
          );
        })}
      </div>
    </div>
  );
};
