import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Layout from "../../layouts/Layout";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { toast, Slide } from "react-toastify";
const UsersPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const getData = () => {
  //   return axios
  //     .get(`http://localhost:8080/api/user/get`)
  //     .then((res) => {
  //       return res.data.userlist;
  //     })
  //     .catch((err) => console.log(err));
  // };

  const [isData, setIsData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(`https://backend.canursvp.com/api/user/get`);

      setIsData(data?.userlist);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (email) => {
    try {
      const { data } = await axios.delete(
        `https://backend.canursvp.com/api/deleteUser/${email}`
      );
      toast.success("Deleted successfully", {
        hideProgressBar: false,
        autoClose: 2000,
        transition: Slide,
        progress: undefined,
      });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Added Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav flex-column gap-3">
            <li>
              <label className="mb-1 text-capitalize fw-bold" htmlFor="email">
                User Email:
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Enter your email..."
              />
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* === */}
      <div className="container">
        {/* Header */}
        <div className="d-flex flex-wrap gap-3 mb-4">
          <h3 className="mb-0 text-capitalize me-auto">List Of Users</h3>
          <div>
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleShow()}
            >
              Edit
            </button> */}
          </div>
        </div>
        {/* /Header */}
        <Table responsive bordered hover>
          <thead>
            <tr className="bg-primary text-white">
              <th className="text-center" style={{ width: "2.5rem" }}>
                #
              </th>
              <th>User Email</th>
              <th>User Name</th>
              <th className="text-center" style={{ width: "6.25rem" }}>
                Action
              </th>
            </tr>
          </thead>
          {console.log(isData)}
          <tbody>
            {isData?.map((item, i) => (
              <tr key={item._id}>
                <td className="text-center fw-bold">{i}</td>
                <td>{item.email}</td>
                <td>{item.username}</td>
                <td>
                  <div className="d-flex gap-2 justify-content-center">
                    <button
                      onClick={() => handleDelete(item?.email)}
                      type="button"
                      className="btn btn-sm btn-outline-danger rounded-circle"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default UsersPage;
