import { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import FontPicker from "font-picker-react";
import {
  FaCaretDown,
  FaCaretUp,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignJustify,
  FaAlignRight,
} from "react-icons/fa";

export const Box = ({
  id,
  left,
  top,
  hideSourceOnDrag,
  CustomMessage,
  setBoxes,
  boxes,
  editTitle,
  editColor,
  color,
}) => {
  const [activeFontFamily, setFont] = useState("Open Sans");
  const [fontSize, setFontSize] = useState(16);
  const [textColor, setTextColor] = useState("");
  const [textAlign, setTextAlign] = useState("center");
  const [fontWeight, setFontWeight] = useState("normal");
  const [messageSelect, setMessage] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleAddrTypeChange(e) {
    setFontWeight(e.target.value);
  }

  const handleSliderChange = (e) => {
    setFontSize(e.target.value);
  };

  const colorsList = [
    { color: "#000" },
    { color: "#C65C82" },
    { color: "#464648" },
    { color: "#D01D3D" },
    { color: "#7C5A6A" },
    { color: "#502F4E" },
    { color: "#FFB400" },
    { color: "#E53D39" },
    { color: "#2C91DE" },
    { color: "#FFFFFF" },
    { color: "#970E65" },
    { color: "#650633" },
    { color: "#F3A2A3" },
    { color: "#D44F70" },
    { color: "#97070E" },
    { color: "#650508" },
    { color: "#F35D19" },
    { color: "#9E5727" },
    { color: "#D8B571" },
    { color: "#978454" },
    { color: "#65320C" },
    { color: "#8793D6" },
    { color: "#866AA5" },
    { color: "#552978" },
    { color: "#1D549F" },
    { color: "#073464" },
    { color: "#55B2AE" },
    { color: "#686868" },
    { color: "#D5D5D5" },
    { color: "#FFFAA3" },
    { color: "#C0CFAF" },
    { color: "#469E57" },
  ];

  const textAlignList = [
    { align: "left", icon: <FaAlignLeft /> },
    { align: "center", icon: <FaAlignCenter /> },
    { align: "justify", icon: <FaAlignJustify /> },
    { align: "right", icon: <FaAlignRight /> },
  ];

  const messagesList = [
    { message: `Thanks- not just for what you do, but for being you!` },
    {
      message: `I would thank you from the bottom of my heart, but for you my
    heart has no bottom.`,
    },
    { message: `You shouldn't have, but I'm glad you did! Thanks.` },
    {
      message: `A note to thank you.. for the beauty you bring into the world.`,
    },
    {
      message: `I've never been more pleased... and it's all your fault! Thank You`,
    },
    {
      message: `Just a note to thank you and a line to tell you, too nothing was
    more welcomed than the lovely gift from you.`,
    },
    {
      message: `Thank you much more, Than a greeting can say, Because you were
    thoughtful, In such a nice way!`,
    },
    { message: `Thanks for being there for me.` },
    {
      message: `One person can make a difference … and that person is you. Thank
    you.`,
    },
    { message: `Silent gratitude isn’t much use to anyone.` },
    {
      message: `Thank You! I really appreciate you, Your helpful, giving ways, And
    how your generous heart Your unselfishness displays. I thank you
    for your kindness, I will not soon forget; You’re one of the
    nicest people I have ever met. By Joanna Fuchs`,
    },
    {
      message: `Thank you for Your friendship true. For being there, For being
    you. For your heart So good and kind A truer friend I could ever
    find. Thank You.`,
    },
    {
      message: `Thank you for being our guardian angel! Having come to our rescue
    in our time of need. Angels love people as parents love children,
    Nor could we a better have found of the breed, Knowing how hard
    such a one is to wangle! Yet you, with your wings and your halo
    half-hidden, On us have descended with glory unbidden, Undoing the
    darkness our fate had decreed.`,
    },
  ];
  useEffect(() => {
    setTextColor(color);
  }, [color]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ul
          className="editing-popup nav flex-column border-0 "
          style={{ gap: "0.7rem" }}
        >
          <li>
            <FontPicker
              apiKey="AIzaSyCrAmkqacuiyammyv400dq-l6QUNZkoMSE"
              activeFontFamily={activeFontFamily}
              onChange={(nextFont) => setFont(nextFont.family)}
            />
          </li>
          <li>
            <select
              defaultValue={fontWeight}
              onChange={handleAddrTypeChange}
              className="form-select form-select-sm text-capitalize"
            >
              <option value="lighter">lighter</option>
              <option selected value="normal">
                normal
              </option>
              <option value="italic">italic</option>
              <option value="bold">bold</option>
              <option value="bolder">bolder</option>
            </select>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div className="d-flex text-muted">
                <span className="small">
                  <small>A</small>
                </span>
                <FaCaretDown size={"0.8rem"} />
              </div>
              <div className="px-1 w-100">
                <Form.Range
                  className="w-100"
                  min={10}
                  max={70}
                  defaultValue={
                    typeof fontSize === "number"
                      ? fontSize
                      : fontSize === "number"
                  }
                  onChange={handleSliderChange}
                />
              </div>
              <div className="d-flex text-muted">
                <span>A</span>
                <FaCaretUp size={"0.8rem"} />
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex flex-wrap" style={{ gap: "0.4rem" }}>
              {colorsList.map((colorsList) => (
                <button
                  key={colorsList.color}
                  type="button"
                  className={`btn p-0 color-box ${
                    textColor === colorsList.color && "active"
                  }`}
                  onClick={() => {
                    editColor(id, colorsList.color);
                    setTextColor(colorsList.color);
                  }}
                  style={{
                    backgroundColor: `${colorsList.color}`,
                  }}
                />
              ))}
            </div>
          </li>
          <li>
            <div
              className="d-flex align-items-center justify-content-between border-top border-bottom py-2"
              style={{ gap: "0.6rem" }}
            >
              {textAlignList.map((textAlignList) => (
                <button
                  key={textAlignList.align}
                  type="button"
                  className={`btn align-box p-0 border-0 ${
                    textAlign === textAlignList.align && "active"
                  }`}
                  onClick={() => setTextAlign(textAlignList.align)}
                >
                  {textAlignList.icon}
                </button>
              ))}
            </div>
          </li>
          <li>
            <button
              type="button"
              className="btn btn-outline-dark w-100"
              onClick={handleShow}
            >
              Suggested Messages
            </button>
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "box",
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );
  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }

  const handleInput = (e) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const caretPosition = range.endOffset; // Get caret position before update

    const newContent = e.target.textContent;

    editTitle(id, newContent);

    // Restore caret position
    setTimeout(() => {
      const node = e.target.firstChild;

      // Ensure that the node is a text node and exists
      if (node && node.nodeType === Node.TEXT_NODE) {
        const newRange = document.createRange();
        const newSelection = window.getSelection();

        // Check if caret position is within the text node length
        if (caretPosition <= node.textContent.length) {
          newRange.setStart(node, caretPosition);
          newRange.setEnd(node, caretPosition);

          newSelection.removeAllRanges();
          newSelection.addRange(newRange);
        }
      }
    }, 0);
  };

  return (
    <>
      {console.log(color, textColor)}
      <Modal size="md" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suggested Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="img-content">
            {messagesList.map((messagesList) => (
              <li
                key={messagesList.message}
                onClick={() => {
                  handleClose();
                  setMessage(messagesList.message);
                }}
              >
                {messagesList.message}
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>

      <OverlayTrigger trigger="click" placement="auto" overlay={popover}>
        <div
          className="box"
          ref={drag}
          style={{ left, top, textAlign: textAlign }}
          data-testid="box"
        >
          <div
            style={{
              fontFamily: activeFontFamily,
              fontSize: `${fontSize}px`,
              color: `${textColor}`,
              fontWeight: fontWeight,
              fontStyle: fontWeight,
            }}
          >
            <p
              onInput={handleInput}
              contenteditable="true"
              style={{ fontFamily: "inherit", color: `${textColor}` }}
            >
              {messageSelect ? messageSelect : CustomMessage}
            </p>
          </div>
        </div>
      </OverlayTrigger>
    </>
  );
};
