import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import Layout from "../../layouts/Layout";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";
import DeleteCategory from "./DeleteCategory";
import { toast, Slide } from "react-toastify";

const CategoriesPage = () => {
  const [show, setShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [subShow, setSubShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedDel, setSelectedDel] = useState({ id: null, type: "" });
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const { data } = await axios.get(`https://backend.canursvp.com/api/category`);
      setData(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefresh = () => {
    window.location.reload(false);
  };

  const handleDelete = async (val) => {
    try {
      const response = await axios.post(
        "https://backend.canursvp.com/api/deleteCategory",
        val
      );
      toast.success("Deleted successfully", {
        hideProgressBar: false,
        autoClose: 2000,
        transition: Slide,
        progress: undefined,
      });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <AddSubCategory
        show={subShow}
        handleClose={() => setSubShow(false)}
        refresh={getData}
        category={selected}
      />
      <AddCategory
        show={addShow}
        handleClose={() => setAddShow(false)}
        refresh={getData}
      />
      {/* <DeleteCategory
        show={show}
        handleClose={() => setShow(false)}
        data={selectedDel}
        refresh={handleRefresh}
      /> */}
      <div className="container">
        {/* Header */}
        <div className="d-flex flex-wrap gap-3 mb-4">
          <h3 className="mb-0 text-capitalize me-auto">List Of Categories</h3>
          <div>
            <button
              onClick={() => setAddShow(true)}
              type="button"
              className="btn btn-primary"
            >
              Add Category
            </button>
          </div>
        </div>
        {/* /Header */}
        <Table responsive bordered hover>
          <thead>
            <tr className="bg-primary text-white">
              <th className="text-center" style={{ width: "2.5rem" }}>
                #
              </th>
              <th>Categories</th>
              <th className="text-center" style={{ width: "6.25rem" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {console.log(data)}
            {data?.map((item, i) => (
              <tr key={i}>
                <td className="text-center fw-bold">{i}</td>
                <td>
                  <Accordion>
                    <Accordion.Item eventKey={item._id}>
                      <Accordion.Header>
                        <span className="text-capitalize">
                          {item?.categoryName}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol className="ps-3 mb-0">
                          {item?.subcategories?.map((v) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <li key={v?._id}>{v?.name}</li>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-danger rounded-circle"
                                  onClick={() => {
                                    handleDelete({
                                      id: v._id,
                                      type: "subcateogry",
                                    });
                                  }}
                                >
                                  <i className="bi bi-trash"></i>
                                </button>
                              </div>
                            );
                          })}
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </td>
                <td>
                  <div className="d-flex gap-2 justify-content-center">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary rounded-circle"
                      onClick={() => {
                        setSelected({
                          categoryId: item._id,
                          categoryName: item?.categoryName,
                        });

                        setSubShow(true);
                      }}
                    >
                      <i className="bi bi-dpad-fill"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger rounded-circle"
                      onClick={() =>
                        handleDelete({ id: item._id, type: "category" })
                      }
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default CategoriesPage;
