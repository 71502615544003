import CardPage from "./pages/cards/CardPage";
import CardsPage from "./pages/cards/CardsPage";
import CategoriesPage from "./pages/categories/CategoriesPage";
import UsersPage from "./pages/users/UsersPage";

const paths = [
  { path: "/", component: <UsersPage /> },
  { path: "/categories", component: <CategoriesPage /> },
  { path: "/cards", component: <CardsPage /> },
  { path: "/cards/:id", component: <CardPage /> },
];

export default paths;
